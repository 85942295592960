import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "./PageTitle";
import {
  FaLanguage,
  FaDribbble,
  FaEnvelopeOpenText,
  FaWhatsapp,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaTwitter,
} from "react-icons/fa";
const NotFound = () => {
  return (
    <>
      <PageTitle title="WS"></PageTitle>
      <div className="h-screen w-full flex flex-col justify-center items-center  bg-homeBg-dark bg-no-repeat bg-center bg-cover bg-fixed">
        <h1 className="text-7xl leading-none text-white font-regular md:text-3xl">
          Click en el botón para enviar un ws directamente.
        </h1>
        <p>

-
+
</p>
         <a href="https://wa.me/+5491135671271"  target="_blank" rel="noopener noreferrer" > <span className="socialbtn text-[#e14a84] "> <FaWhatsapp /> </span>
            </a>
        <p>

          -
        </p>

        <Link
          to="/"
          className="flex items-center mx-auto bg-gradient-to-r from-[#FA5252] to-[#DD2476] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#DD2476]  to-[#fa5252ef] px-8 py-3 text-lg text-white rounded-[5px]"
        >
          Volver al Home
        </Link>
      </div>
    </>
  );
};

export default NotFound;
