import { useEffect, useState } from "react";
// for work_images
import work1 from "../assets/images/work_images/1.jpg";
import work2 from "../assets/images/work_images/2.jpg";
import work3 from "../assets/images/work_images/3.jpg";
import work4 from "../assets/images/work_images/4.jpg";
import work5 from "../assets/images/work_images/5.jpg";
import work6 from "../assets/images/work_images/6.jpg";
import work7 from "../assets/images/work_images/7.jpg";
import work8 from "../assets/images/work_images/8.jpg";
import work9 from "../assets/images/work_images/9.jpg";
// works small images
import workSmall1 from "../assets/images/work_images/small/1.jpg";
import workSmall2 from "../assets/images/work_images/small/2.jpg";
import workSmall3 from "../assets/images/work_images/small/3.jpg";
import workSmall4 from "../assets/images/work_images/small/4.jpg";
import workSmall5 from "../assets/images/work_images/small/5.jpg";
import workSmall6 from "../assets/images/work_images/small/6.jpg";
import workSmall7 from "../assets/images/work_images/small/7.jpg";
import workSmall8 from "../assets/images/work_images/small/8.jpg";
import workSmall9 from "../assets/images/work_images/small/9.jpg";
// blog post images
import blog6 from "../assets/images/blog_images/6.jpg";
import blog4 from "../assets/images/blog_images/4.jpg";
import blog2 from "../assets/images/blog_images/2.jpg";
import blog1 from "../assets/images/blog_images/1.jpg";
import blog3 from "../assets/images/blog_images/3.jpg";
import blog5 from "../assets/images/blog_images/5.jpg";
// blog image small
import blogSmall6 from "../assets/images/blog_images/small/6.jpg";
import blogSmall4 from "../assets/images/blog_images/small/4.jpg";
import blogSmall2 from "../assets/images/blog_images/small/2.jpg";
import blogSmall1 from "../assets/images/blog_images/small/1.jpg";
import blogSmall3 from "../assets/images/blog_images/small/3.jpg";
import blogSmall5 from "../assets/images/blog_images/small/5.jpg";

import img1 from "../assets/images/slider/brand-1.png";
import img2 from "../assets/images/slider/brand-2.png";
import img3 from "../assets/images/slider/brand-3.png";
import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";
//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon2 from "../assets/images/icons/icon-3.svg";
import icon3 from "../assets/images/icons/icon-4.svg";
import icon4 from "../assets/images/icons/icon-5.svg";
import icon5 from "../assets/images/icons/icon-6.svg";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaBlogger, FaRegUser, FaAward, FaWhatsapp, FaShieldAlt} from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(true);
      localStorage.setItem("theme", "dark"); // replace light to dark //
      setLocal("dark"); // ad this code to setlocal dark //
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: blog1,
      imgSmall: blogSmall1,
      date: "Mayo 2020",
      category: "Highlands, Escocia",
      title: "Pico de las highlands.",
      bg: "#FCF4FF",
      description:
        "Sin duda uno de mis lugares favoritos en el mundo, las tierras altas escocesas. Un lugar tranquilo y de enorme belleza, montañas y pueblos antiguos que surcan todo el norte de Escocia. Tengo como objetivo algún dia poder vivir y trabajar en este hermoso lugar.",
    },
    {
      id: "4",
      img: blog4,
      imgSmall: blogSmall4,
      date: "Agosto 2019",
      category: "Giza, Egipto",
      title: "Caminata bajo el sol egipcio.",
      bg: "#EEFBFF",
      description:
        "Uno de los objetivos de viaje desde que tengo memoria, visitar la última de las maravillas antiguas que aún se mantiene en pié. Un conjunto de sensaciones difíciles de explicar, la energía y el misticismo de este lugar es algo único. ",
    },
    {
      id: "2",
      img: blog2,
      imgSmall: blogSmall2,
      date: "Oct. 2018",
      category: "C. del Cabo, Sudáfrica",
      title: "De cabeza sobre Africa.",
      bg: "#FFF0F0",
      description:
        "Foto tomada por un colega en Sudáfrica, país que recorrí junto a National Geographic para mi primera experiencia documental. ",
    },
    {
      id: "5",
      img: blog5,
      imgSmall: blogSmall5,
      date: "Marzo 2018",
      category: "Islas Lofoten, Noruega",
      title: "Un fiordo espejo.",
      bg: "#FCF4FF",
      description:
        "Las Islas Lofoten, en Noruega, son uno de los lugares donde más se palpa el legado vikingo. Sitio original desde donde zarpó el legendario Ragnar Lothbrok hacia los mares del oeste, en busca de nuevas tierras para saquear y conquistar.",
    },
    {
      id: "3",
      img: blog3,
      imgSmall: blogSmall3,
      date: "Febrero 2018",
      category: "Londres, Inglaterra",
      title: "Timelapse en Londres.",
      bg: "#FCF4FF",
      description:
        "Foto tomada con mi queria ex cámara Nikon D810, desde la orilla oriental del Támesis. Imposible no pasar por este lugar mítico, testigo de guerras y del paso de celtas, romanos y vikingos por las islas. ",
    },
    {
      id: "6",
      img: blog6,
      imgSmall: blogSmall6,
      date: "Sept. 2017",
      category: "Bangkok, Tailandia",
      title: "La ciudad de los mil templos.",
      bg: "#EEFBFF",
      description:
        "Durante una de mis visitas al sudeste asiático, utilicé a Tailandia como base de viajes. Sin lugar a dudas una región de contrastes, desde su moderna capital hasta sus infinitas playas e islas en el sur del país.",
    },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/home/HomeTwo",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/home/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/home/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/home/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Blogs",
      link: "/home/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/home/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "Javi",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "CV",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "04",
      name: "Blog",
      link: "/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Email",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
    {
      id: "03",
      name: "Envía ws",
      link: "/ws",
      icon: <FaWhatsapp />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  // experience items for about page
  const experienceArray = [
    {
      id: "1",
      icon: icon1,
      title: "Networking",
      des: "Certificaciones dentro del ámbito CCNA Cisco y Cloud computing. Azure, FTTH, Fibra, Redes Móviles, infraestructura de redes y seguridad informática.",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "2",
      icon: icon,
      title: "Diseño",
      des: "Manejo de herramientas gráficas como Pothoshop, Illustrator, AutoCad, 3DStudio y similares.",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      icon: icon5,
      title: "Coding",
      des: "Bases sobre programación general, Python, HTML, PHP, CSS, XML, MySQL, Node js.",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: "4",
      icon: icon3,
      title: "Retro builds",
      des: "Desarrollo sistemas de juegos retro, desde la concepción del diseño hasta la programación y fabricación. ig:retroboxarcade",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
    {
      id: "5",
      icon: icon4,
      title: "Hardware y OS",
      des: "Basta experiencia a lo que refiere hardware de pc, equipos Apple y servidores. Ensamblado, diagnostico de fallas, sistemas DOS, Linux, Windows y MacOs.",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
    {
      id: "6",
      icon: icon2,
      title: "Fotografía",
      des: "Experiencia en fotografía documental desde 2018, fotógrafo colaborador de National Geographic. ig:rholjavi",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: "Educación",
      icon: MdOutlineSchool,
      id: "01",
      date: "2005-2008",
      title: "Analista de sistemas ",
      place: "UNLP, La Plata, Bs.As",
      bg: "#FFF4F4",

      id1: "02",
      date1: "2010-2011 / 2022-Actualidad",
      title1: "CCNA - CCNA DevNet",
      place1: "Cisco Networking Academy",
      bg1: "#FFF1FB",

      id2: "03",
      date2: "2022",
      title2: "Azure / MySQL / Python ",
      place2: "Innovación y Desarrollo PLAi, MX",
      bg2: "#FFF4F4",

      id3: "04",
      date3: "2023",
      title3: "Seguridad informática",
      place3: "Microsoft SC-900",
      bg3: "#FFF4F4",

    },
    {
      type: "Exp. Laboral",
      icon: MdOutlineBusinessCenter,
      id: "05",
      date: "2012-Actualidad",
      title: "Supervisor de Networking",
      place: "Telecom S.A., BsAs - Arg.",
      bg: "#EEF5FA",

      id1: "06",
      date1: "2009-2012",
      title1: "HTML Web Developer - Arq.",
      place1: "Oca de Arquitetura, Sao Pablo - Br.",
      bg1: "#F2F4FF",

      id2: "07",
      date2: "2007-2009",
      title2: "Sup. técnico corporativo",
      place2: "MZ informática, BsAs - Arg.",
      bg2: "#EEF5FA",

    },
    {
      type: "Seguridad informática",
      icon: FaShieldAlt,
      id: "08",
      title: "Microsoft SC-900",
      place: "Microsoft Security, Compliance, and Identity Fundamentals",
      bg: "#FCF4FF",

      id1: "09",
      title1: "Ethical Hacking",
      place1: "Ethical Hacking Fundamentals",
      bg1: "#FCF9F2",

      id2: "10",
      title2: "Auditoría de Seguridad",
      place2: "Security Audit Essentials",
      bg2: "#FCF4FF",
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "Networking",
      number: "90",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "Diseño ",
      number: "80",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "Harware y sistemas",
      number: "95",
    },
    {
      id: "03",
      color: "#CA56F2",
      name: "Developer",
      number: "75",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+452 666 386",
      item2: "+452 666 386",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "support@gmail.com",
      item2: "example@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Address ",
      item1: "Maount View, Oval",
      item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    experienceArray,
    sliderImg,
    resumeArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
